import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { Chip, Grid } from "@mui/material";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Experience"
				body={
					<div className="works-body">
						<div className="work-container">
							<div className="work">
								<img
									src="/work/tma.jpeg"
									alt="twitter"
									className="work-image"
								/>
								<div className="work-title">The Media Ant</div>
								<div className="work-subtitle">
									Technical Lead
								</div>
								<div className="work-duration">
									Dec 2023 - Present
								</div>
							</div>
							<Grid
								spacing={1}
								columnSpacing={4}
								container
								p="10px"
								display={"flex"}
								width={"100%"}
							>
								<Chip
									size="small"
									variant="outlined"
									label="node.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="react.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="next.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="nest.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="mongodb"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="typescript"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="aws"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="postgres"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="graphql"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="bullmq"
									sx={{ m: "2px" }}
								/>
							</Grid>
						</div>
						<div className="work-container">
							<div className="work">
								<img
									src="/work/tma.jpeg"
									alt="twitter"
									className="work-image"
								/>
								<div className="work-title">The Media Ant</div>
								<div className="work-subtitle">
									Associate Manager
								</div>
								<div className="work-duration">
									Jan 2023 - Dec 2023
								</div>
							</div>
							<Grid
								spacing={1}
								columnSpacing={4}
								container
								p="10px"
								display={"flex"}
								width={"100%"}
							>
								<Chip
									size="small"
									variant="outlined"
									label="node.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="react.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="next.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="nest.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="mongodb"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="typescript"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="aws"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="postgres"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="graphql"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="bullmq"
									sx={{ m: "2px" }}
								/>
							</Grid>
						</div>
						<div className="work-container">
							<div className="work">
								<img
									src="/work/tma.jpeg"
									alt="twitter"
									className="work-image"
								/>
								<div className="work-title">The Media Ant</div>
								<div className="work-subtitle">
									Senior Fullstack Engineer
								</div>
								<div className="work-duration">
									May 2022 - Dec 2022
								</div>
							</div>
							<Grid
								spacing={1}
								columnSpacing={4}
								container
								p="10px"
								display={"flex"}
								width={"100%"}
							>
								<Chip
									size="small"
									variant="outlined"
									label="node.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="react.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="next.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="nest.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="mongodb"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="typescript"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="aws"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="gcp"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="postgres"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="graphql"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="bullmq"
									sx={{ m: "2px" }}
								/>
							</Grid>
						</div>
						<div className="work-container">
							<div className="work">
								<img
									src="/work/flyo.jpeg"
									alt="twitter"
									className="work-image"
								/>
								<div className="work-title">Flyo</div>
								<div className="work-subtitle">
									Sr Fullstack developer Consultant
								</div>
								<div className="work-duration">
									May 2022 - Jun 2022
								</div>
							</div>
							<Grid
								spacing={1}
								columnSpacing={4}
								container
								p="10px"
								display={"flex"}
								width={"100%"}
							>
								<Chip
									size="small"
									variant="outlined"
									label="redux"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="react.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="react native"
									sx={{ m: "2px" }}
								/>

								<Chip
									size="small"
									variant="outlined"
									label="typescript"
									sx={{ m: "2px" }}
								/>
							</Grid>
						</div>
						<div className="work-container">
							<div className="work">
								<img
									src="/work/tripleess.png"
									alt="twitter"
									className="work-image"
								/>
								<div className="work-title">Triple Ess</div>
								<div className="work-subtitle">
									Senior Fullstack Developer
								</div>
								<div className="work-duration">
									Feb 2021 - Jan 2022
								</div>
							</div>
							<Grid
								spacing={1}
								columnSpacing={4}
								container
								p="10px"
								display={"flex"}
								width={"100%"}
							>
								<Chip
									size="small"
									variant="outlined"
									label="node.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="appscript"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="react.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="react native"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="firebase"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="mongodb"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="typescript"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="aws"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="sqs"
									sx={{ m: "2px" }}
								/>
							</Grid>
						</div>
						<div className="work-container">
							<div className="work">
								<img
									src="/work/mit.png"
									alt="twitter"
									className="work-image"
								/>
								<div className="work-title">
									CEMS - vendor Management
								</div>
								<div className="work-subtitle">Founder</div>
								<div className="work-duration">
									Jun 2020 - Dec 2021
								</div>
							</div>
							<Grid
								spacing={1}
								columnSpacing={4}
								container
								p="10px"
								display={"flex"}
								width={"100%"}
							>
								<Chip
									size="small"
									variant="outlined"
									label="node.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="appscript"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="sqlite"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="react native"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="mongodb"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="javscript"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="aws"
									sx={{ m: "2px" }}
								/>
							</Grid>
						</div>
						<div className="work-container">
							<div className="work">
								<img
									src="/work/lprs.png"
									alt="facebook"
									className="work-image"
								/>
								<div className="work-title">LPRS</div>
								<div className="work-subtitle">Fullstack developer</div>
								<div className="work-duration">
									Oct 2017 - Jan 2019
								</div>
							</div>
							<Grid
								spacing={1}
								columnSpacing={4}
								container
								p="10px"
								display={"flex"}
								width={"100%"}
							>
								<Chip
									size="small"
									variant="outlined"
									label="react native"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="node.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="react.js"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="mongodb"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="javascript"
									sx={{ m: "2px" }}
								/>
								<Chip
									size="small"
									variant="outlined"
									label="firebase"
									sx={{ m: "2px" }}
								/>
							</Grid>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
