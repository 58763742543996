import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTwitter,
	faGithub,
	faInstagram,
	faGitlab,
	faMedium,
	faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import ReactGA from "react-ga4";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import Article from "../components/homepage/article";
import Works from "../components/homepage/works";
import AllProjects from "../components/projects/allProjects";
import { Button, Box } from "@mui/material";
import { Download, Note } from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import INFO from "../data/user";
import SEO from "../data/seo";
import myArticles from "../data/articles";
import mainPhoto from "../images/main/main-photo.png";
//import Experience from "../components/homepage/Experience";

import "./styles/homepage.css";
import { Link } from "react-router-dom";

const Homepage = () => {
	const [stayLogo, setStayLogo] = useState(false);
	const [logoSize, setLogoSize] = useState(80);
	const [oldLogoSize, setOldLogoSize] = useState(80);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			let scroll = Math.round(window.pageYOffset, 2);

			let newLogoSize = 80 - (scroll * 4) / 10;

			if (newLogoSize < oldLogoSize) {
				if (newLogoSize > 40) {
					setLogoSize(newLogoSize);
					setOldLogoSize(newLogoSize);
					setStayLogo(false);
				} else {
					setStayLogo(true);
				}
			} else {
				setLogoSize(newLogoSize);
				setStayLogo(false);
			}
		};

		ReactGA.send({ hitType: "pageview", page: "/", title: "homepage" });

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [logoSize, oldLogoSize]);

	const currentSEO = SEO.find((item) => item.page === "home");

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="fixed-size-container">
				<NavBar active="" />
				<div className="content-wrapper">
					<div className="homepage-container">
						<div className="homepage-first-area">
							<div className="homepage-first-area-left-side">
								<div className="title homepage-title">
									{INFO.homepage.title}
								</div>

								<div className="subtitle homepage-subtitle">
									{INFO.homepage.description}
								</div>
							</div>

							<div className="homepage-first-area-right-side">
								<div className="mainPhoto">
									<img
										src={mainPhoto}
										className="homepage-image-wrapper"
									/>
								</div>
							</div>
						</div>

						<Link
							to="https://tma-live.s3.ap-south-1.amazonaws.com/campaigns/6728632fb4b5407bee03b3ad/1730743379318-Prajwal-Anjanappa.pdf"
							target="_blank"
							onClick={() => {
								ReactGA.send({
									hitType: "pageview",
									page: "/",
									title: "resume-download",
								});
							}}
						>
							<Button
								variant="contained"
								sx={{
									mt: { xs: 4, md: 1 },
									":hover": {
										backgroundColor: "black",
									},
								}}
								className="bounceBtn"
							>
								Download Resume
								<Download sx={{ ml: 2 }} />
							</Button>
						</Link>

						<h2
							style={{ marginBottom: "-10px", marginTop: "40px" }}
						>
							Reach out to me
						</h2>
						<div className="homepage-socials">
							<a
								href={INFO.socials.linkedin}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faLinkedin}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.twitter}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faTwitter}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.github}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faGithub}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.gitlab}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faGitlab}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.instagram}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faInstagram}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.medium}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faMedium}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={`mailto:${INFO.main.email}`}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faMailBulk}
									className="homepage-social-icon"
								/>
							</a>
						</div>
						<h2 style={{ marginTop: "40px" }}>Services</h2>

						<Box
							sx={{
								display: {
									xs: "block",
									md: "flex",
								},
							}}
						>
							<Card
								sx={{ maxWidth: 345, mr: { xs: 0, md: 5 } }}
								component={"a"}
							>
								<Link to={"/contact#contact-me"}>
									<CardActionArea>
										<CardMedia
											component="img"
											height="140"
											image="/consult.webp"
											alt="green iguana"
										/>
										<CardContent>
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontWeight={700}
												color={"text.mid"}
											>
												Consult regarding your MVP
											</Typography>
											<Typography
												variant="body2"
												color="text.light"
												fontSize={"16px"}
												lineHeight={1.3}
											>
												Got an idea? Let's shape your
												Minimum Viable Product together!
												Book a one-on-one session where
												we'll dive deep into your
												concept, streamline your
												features, and devise a launch
												strategy tailored just for you.
												Perfect for startups and
												entrepreneurs ready to make a
												mark.
											</Typography>
										</CardContent>
									</CardActionArea>
								</Link>
							</Card>
							<Card sx={{ maxWidth: 345, mt: { xs: 5, md: 0 } }}>
								<Link to={"/contact#contact-me"}>
									<CardActionArea>
										<CardMedia
											component="img"
											height="140"
											image="/hire.webp"
											alt="green iguana"
										/>
										<CardContent>
											<Typography
												gutterBottom
												variant="h3"
												component="div"
												fontWeight={700}
												color={"text.mid"}
											>
												Hire Me
											</Typography>
											<Typography
												variant="body2"
												color="text.light"
												fontSize={"16px"}
												lineHeight={1.3}
											>
												Need expert hands on your
												project? I'm available for hire!
												Whether it's software
												development or project
												management, I bring a wealth of
												knowledge and a creative touch
												to ensure your project's success
												from start to finish.
											</Typography>
										</CardContent>
									</CardActionArea>
								</Link>
							</Card>
						</Box>

						<div className="homepage-projects">
							<Box
								display={"flex"}
								justifyContent={"space-between"}
								alignItems={"center"}
							>
								<h2
									style={{
										margin: "0px",
										marginBottom: "-15px",
									}}
								>
									Projects
								</h2>
								<Link to="/projects">
									<Button
										variant="outlined"
										sx={{
											mt: { xs: 4, md: 1 },
											height: "36px",
											fontSize: "16px",
											fontWeight: 400,
										}}
									>
										See all projects
									</Button>
								</Link>
							</Box>
							<AllProjects short={true} clamp={8} />
						</div>
						<div className="homepage-after-title">
							<div className="homepage-articles">
								{myArticles.map((article, index) => (
									<div
										className="homepage-article"
										key={(index + 1).toString()}
										onClick={() =>
											ReactGA.send({
												hitType: "pageview",
												page: "/",
												title: "article",
											})
										}
									>
										<Article
											key={(index + 1).toString()}
											date={article().date}
											title={article().title}
											description={article().description}
											link={"/article/" + (index + 1)}
										/>
									</div>
								))}
							</div>

							<div className="homepage-works">
								<Works />
							</div>
						</div>
						<div className="page-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;
